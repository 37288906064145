import React from 'react'
import { Grid, Typography, makeStyles, Hidden } from '@material-ui/core'
import ImageComponent from './GeneralComponents/ImageComponent'
import PrimaryButton from './GeneralComponents/Buttons/PrimaryButton'
import generalCss from '../styles/generalCss'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: generalCss.primaryFadedBgColor,
        width: '100%'
    },
    mainContainer: {
        flexDirection: "column-reverse",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row"
        }
    },
    container: {
        padding: "20px",
        paddingBottom: "30px",
        [theme.breakpoints.up('md')]: {
            padding: "2rem",
            paddingTop: "2.5rem",
            paddingBottom: "80px"
        },
        maxWidth: generalCss.appMaxWidth
    },
    subHeading: {
        // lineHeight: "1.5rem",
        // [theme.breakpoints.up('sm')]: {
        //     lineHeight: "2rem"
        // }
    },
    imageStyles: {
        width: "100%",
        height: "100%",
        objectFit: 'cover',
        maxHeight: "250px",
        [theme.breakpoints.up('sm')]: {
            maxHeight: '300px'
        },
        [theme.breakpoints.up('md')]: {
            maxHeight: '450px'
        }
    }
}))

function ItemSection({
    title = '', titleVariant = 'h1', subHeadings = [], buttonTitle = '', buttonClickAction = () => null,
    coverImageWeb = '', coverImageMobile = '', showButton = true, flexDirection = 'row', buttonHref = '', titleClassName
}) {
    const classes = useStyles()
    return <Grid container className={classes.mainContainer} style={{
        margin: 'auto',
        maxWidth: generalCss.appMaxWidth
    }} >
        <Grid item xs={12} md={6}>
            <div className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant={titleVariant} component='h1' color='textPrimary' className={titleClassName} >{title}</Typography>
                    </Grid>
                    {
                        subHeadings.map(subHeading => {
                            const { id, value } = subHeading
                            return <Grid item key={id} xs={12}>
                                <Typography color='textPrimary' className={classes.subHeading} >{value}</Typography>
                            </Grid>
                        })
                    }
                    {
                        showButton &&
                        <Grid item xs={8} sm={5}>
                            <PrimaryButton href={buttonHref}>
                                {buttonTitle}
                            </PrimaryButton>
                        </Grid>
                    }
                </Grid>
            </div>
        </Grid>
        <Grid item xs={12} md={6}>
            <Hidden smDown implementation='css'>
                <div style={{ paddingRight: "2rem" }}>
                    <ImageComponent
                        className={classes.imageStyles}
                        src={!!coverImageWeb.url ? coverImageWeb.url : ''}
                        alt={!!coverImageWeb.alternativeText ? coverImageWeb.alternativeText : 'cover-web'} />
                </div>
            </Hidden>
            <Hidden mdUp implementation='css'>
                <ImageComponent
                    className={classes.imageStyles}
                    src={!!coverImageMobile.url ? coverImageMobile.url : ''}
                    alt={!!coverImageMobile.alternativeText ? coverImageMobile.alternativeText : 'cover-mobile'} />
            </Hidden>
        </Grid>
    </Grid>
}

function Hero({
    ...props
}) {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            {/* <Hidden mdUp implementation='css'>
               
            </Hidden>
            <Hidden smDown implementation='css'>
                <ItemSection flexDirection='row' {...props} />
            </Hidden > */}
            <ItemSection flexDirection='column-reverse' {...props} />
        </div >
    )
}

export default Hero
