import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import CaseStudyCardFeatured from './CaseStudyCardFeatured'
import CaseStudyCardNonFeatured from './CaseStudyCardNonFeatured'
import LinkButton from '../GeneralComponents/Buttons/LinkButton'
import Routes from '../../utilities/Routes'
import ImageComponent from '../GeneralComponents/ImageComponent'
import RightArrow from '../SVGs/RightArrow'

const useStyles = makeStyles(theme => ({
    gridItem: {
        [theme.breakpoints.down('xs')]: {
            padding: "28px 0px !important"
        }
    }
}))

export default function CaseStudyCards({ cardList = [], featured = false, showViewAllButton = false }) {
    const classes = useStyles()
    return (
        <div>
            <Grid container spacing={5} alignItems='stretch'>
                {
                    cardList.map((item, index) => {
                        if (featured) {
                            return <Grid item key={item.id}>
                                <CaseStudyCardFeatured data={item} index={index} />
                            </Grid>
                        }
                        return <Grid item key={item.id} xs={12} sm={6} md={4}>
                            <CaseStudyCardNonFeatured data={item} />
                        </Grid>
                    })
                }
                {
                    !featured && showViewAllButton &&
                    <Grid item xs={12}>
                        <Grid container justifyContent='center'>
                            <Grid item>
                                <LinkButton className={classes.buttonStyles} href={Routes.caseStudies}>
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item>
                                            View All Case Studies
                                        </Grid>
                                        <Grid item style={{ display: 'flex' }}>
                                            <RightArrow />
                                        </Grid>
                                    </Grid>
                                </LinkButton>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </div>
    )
}
