import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    tagContainer: {
        backgroundColor: '#cce3f8',
        padding: "4px 12px",
        borderRadius: "8px"
    },
    tag: {
        color: '#263b50',
        fontWeight: "bold",
        fontSize: '0.625rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.75rem'
        }
    }
}))


export default function TagComponent({ array = [], spacing = 2 }) {
    const classes = useStyles()
    return (
        <Grid container spacing={spacing}>
            {
                array.map(item => {
                    return <Grid item key={item}>
                        <div className={classes.tagContainer}>
                            <Typography variant='body2' className={classes.tag}>{item}</Typography>
                        </div>
                    </Grid>
                })
            }
        </Grid>
    )
}
