import React from 'react'
import { Typography, makeStyles, Grid, Hidden } from '@material-ui/core'
import LinkComponent from './GatsbyComponents/LinkComponent'
import generalCss from '../styles/generalCss'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    container: {
        maxWidth: generalCss.appMaxWidth,
        margin: 'auto',
        minHeight: "50px",
        // padding: "0px 50px",
        display: 'flex',
        alignItems: 'center'
    },
    subContainer: {
        padding: "20px",
        borderBottom: "1px solid #E6E6E6",
        [theme.breakpoints.up('md')]: {
            padding: "20px 2rem",
        }
    },
    link: {
        fontSize: '1rem',
        lineHeight: "1.5rem",
        fontWeight: "700",
        color: "rgba(38, 59, 80, 0.6)",
        whiteSpace: 'break-spaces'
    }
}))

export default function BreadCrumbComponent({
    crumbsList = []
}) {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Hidden xsDown implementation='css'>
                <div className={classes.container}>
                    <Grid container alignItems='center' className={classes.subContainer}>
                        {
                            crumbsList.map(item => {
                                const labelValue = !!item.route ? `${item.label} / ` : item.label
                                return <Grid item key={item.id}>
                                    {
                                        !!item.route ?
                                            <LinkComponent path={item.route} className={classes.link}>
                                                {labelValue}
                                            </LinkComponent>
                                            :
                                            <Typography className={classes.link}>{labelValue}</Typography>
                                    }

                                </Grid>
                            })
                        }
                    </Grid>
                </div>
            </Hidden>
        </div>
    )
}
